import React, { useEffect } from "react"
import "./timeline.scss"

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import SliderNavigation from '../../components/SharedComponents/SliderNavigation'

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

import $ from "jquery/dist/jquery.slim"

import { createMarkup } from "../../../utils"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

export default function Timeline(props) {
  const swiperRef = React.useRef(null)
  const swiperRef2 = React.useRef(null)
  let array

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const handleScroll = () => {
    if ($(".timeline-axe-wrapper").length <= 0) return;

    if ($(window).scrollTop() >= $(".timeline-axe-wrapper").offset().top - 90) {
      $(".timeline-axe").css({
        position: "fixed",
        top: "90px",
        "z-index": "1000",
      })
    } else {
      $(".timeline-axe").css({ position: "relative", top: "initial" })
    }
    $(".bike").css("transform", "translateX(0)")


    function functiondisable() {
      // To get the scroll position of current webpage
      const TopScroll = window.pageYOffset || document.documentElement.scrollTop;
      const LeftScroll = window.pageXOffset || document.documentElement.scrollLeft;
      
      // if scroll happens, set it to the previous value
      window.onscroll = function() {
      window.scrollTo(LeftScroll, TopScroll);
              };
      }
      
      function functionenable() {
      window.onscroll = function() {};
      }

    var lastItem = array[array.length - 1];

    array.each(function (index, wrapper) {
      if ($(window).scrollTop() >= $(wrapper).offset().top - 350) {
        swiperRef2.current.swiper?.slideTo(index)
        if ($(window).scrollTop() < lastItem.offsetTop -60) {
          if (locale === "ar") {
            $(".bike").css({
              transform: "translateX( -" + 105 * index + "px)",
              transition: "0.5s ease",
            })
          } else {
            $(".bike").css({
              transform: "translateX(" + 105 * index + "px)",
              transition: "0.5s ease",
            })
          }
        }
        else{
          if (locale === "ar") {
            $(".bike").css({
              transform: "translateX( -" + 105 * [array.length - 1] + "px)",
              transition: "0.5s ease",
            })
          } else {
            $(".bike").css({
              transform: "translateX(" + 105 * [array.length - 1] + "px)",
              transition: "0.5s ease",
            })
          }
        }
      }
    })
    
        
  }

  const handleScrollTo = (index) => {
    const getAssociatedWrapper = array[index]

    swiperRef?.current?.swiper?.slideTo(index)
    if (locale === "ar") {
      $(".bike").css({"transform":"translateX( -" + 105 * index + "px)","transition": "0.5s ease"})
    } else {
      $(".bike").css({"transform":"translateX(" + 105 * index + "px)","transition": "0.5s ease"})
    }
    
    window.scrollTo(0,getAssociatedWrapper.offsetTop)
  }
  const shouldRenderArrows = props.data.length > 1

  useEffect(() => {
    array = $(".time-line-wrapper")
    window.addEventListener("scroll", handleScroll)
  }, [])
  return (
    <>
      <section className="timeline-axe-wrapper">
        <div className="timeline-axe">
          <div className="container">
            <Swiper
              id="timelinetopswiper"
              spaceBetween={0}
              slidesPerView={"auto"}
              grabCursor={"true"}
              freeMode={"true"}
              ref={swiperRef2}
            >
              <div slot="wrapper-start" className="bike">
                <img src="/images/project-update/bike.svg" alt="Bike" />
              </div>
              {props.data.map((year, index) => (
                <SwiperSlide className="year-arrow" key={"y"+index} onClick={() => handleScrollTo(index)} role = "button">
                  <div className="year">{year.projectUpdateYear}</div>
                  <div className="arrow-down">
                    <img src="/images/project-update/arrow-down.svg" alt="Arrow Down" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
      <section className="timeline">
        <div className="container">
          {props.data.map((updates, index) => (
            <div
              className={`time-line-wrapper ${updates.projectPosition} timeline-${index}`}
              key={"t"+index}
            >
              <div
                className={
                  "desc " + updates.projectPosition + " " + updates.projectColor
                }
              >
                <div className="desc-wrapper">
                  <div className={"year h2 " + updates.projectColor}>
                    {updates.projectUpdateYear}
                  </div>
                  <div
                    className="title h2"
                    dangerouslySetInnerHTML={createMarkup(
                      updates.projectUpdateTitle
                    )}
                  ></div>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={createMarkup(
                      updates.projectUpdateText
                    )}
                  ></div>
                </div>
              </div>
              <div
                className={
                  "logo " + updates.projectPosition + " " + updates.projectColor
                }
              >
                {updates.addBlackBorder ? (
                  updates.projectUpdateImage.length > 1 ? (
                    <div className="timeline-slider">
                      <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination]}
                        pagination={{ clickable: true }}
                        id="timeline-swiper"
                        spaceBetween={5}
                        slidesPerView={1}
                        slidesPerGroup={1}
                        grabCursor={"true"}
                        ref={swiperRef}
                      >
                        {updates.projectUpdateImage.map((item, index) => (
                          <SwiperSlide key={index}>
                            <img src={item?.localFile?.publicURL} alt="Project Update" />
                          </SwiperSlide>
                        ))}

                        {shouldRenderArrows && <SliderNavigation ref={swiperRef} />}
                      </Swiper>
                    </div>
                  ) : (
                    <div className="img-wrapper">
                      <img
                        src={updates.projectUpdateImage[0].localFile?.publicURL}
                        alt="Timeline"
                      />
                    </div>
                  )
                ) : (
                  <img
                    src={updates.projectUpdateImage[0].localFile?.publicURL}
                    alt="Timeline"
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}
