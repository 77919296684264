import * as React from "react"
import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import Layout from "../components/layout"
import WhoWeAre from "../components/WhoWeAre"
import Timeline from "../components/Timeline"
// import SliderPath from "../components/SliderPath"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

export default function ProjectUpdate(pageData) {
  const data = pageData?.data
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri

  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
      <WhoWeAre
          title={data.wpPage.pageSubtitle?.subtitle}
          name={data.wpPage?.title}
          description={data.wpPage.pageDescription?.pageDescription}
        />
        {/* <SliderPath/> */}
        <Timeline data={data.wpPage.projectUpdates.projectSingleUpdate}/>
      </Layout>
    </>
  )
}


export const pageQuery = (graphql` query projectUpdateData($databaseId:Int) {
  wpPage(databaseId: {eq: $databaseId}) {
    id
    title
    pageSubtitle {
      fieldGroupName
      subtitle
    }
    date
    pageDescription {
      fieldGroupName
      pageDescription
    }
    projectUpdates {
      projectSingleUpdate {
        addBlackBorder
        fieldGroupName
        projectColor
        projectPosition
        projectUpdateImage {
          localFile {
            publicURL
          }
        }
        projectUpdateText
        projectUpdateTitle
        projectUpdateYear
      }
    }
  }
}`);
